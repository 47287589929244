import React, { useEffect } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt, FaOtter } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import Footer from "../Footer/Footer";

const Disclimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <MovieSliders />
        <Text
          textAlign="center"
          color="red"
          fontFamily="lemon"
          fontWeight="600"
        >
          Just Remember 'MoviesHub4u' to visit us if Blocked
        </Text>
        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MovieHub4U: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <Box display="flex" marginTop="10px" justifyContent="center">
          <FaFireAlt
            style={{
              color: "white",
              width: "40px",
              height: "40px",
              marginRight: "20px",
            }}
          />
          <Text color="white" fontFamily="lemon" fontSize="xx-large">
            Request Your Movies
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="red"
            fontSize="xx-large"
            fontWeight="500"
            textAlign="center"
          >
            ...::MoviesHub4u::...
          </Text>
          <Text
            color="green"
            fontSize="xx-large"
            fontWeight="500"
            textAlign="center"
          >
            Kindly search on our site before making a request..
          </Text>
          <Text
            color="blue"
            fontSize="xx-large"
            fontWeight="500"
            textAlign="center"
          >
            Make Request Down in Comments !!!
          </Text>
        </Box>
        <Center>
          {" "}
          <Box
            display="block"
            marginTop="10px"
            justifyContent="center"
            border="1px solid white"
            width="80%"
            padding="8px"
          >
            <Text
              color="white"
              fontSize="xx-large"
              fontWeight="900"
              textAlign="center"
              borderTop="1px solid #252525"
              borderBottom="1px solid #252525"
            >
              #Rules
            </Text>
            <Text
              color="white"
              fontSize="large"
              fontWeight="200"
              textAlign="center"
            >
              1- Don’t ask for specific host or size (i will give you the best){" "}
              <br /> 2- You are free to tell me about the Quality you need{" "}
              <br /> 3- Don’t ask for another request before your first request
              was finished <br />
              4- Don’t ask twice for the same request <br /> 5- All requests
              will processed respectively <br /> 6- Give me IMDB link for movies
              to make it easy <br />
              7- No more TV Shows in request page
              <br /> 8- I am not a robot so give me some rest
              <br /> 9- Please Mention Your request with Quality And Size Its
              Important
            </Text>
            <Text
              color="red"
              fontSize="xx-large"
              fontWeight="700"
              textAlign="center"
              borderTop="1px solid #252525"
              borderBottom="1px solid #252525"
            >
              ...::MoviesHub4u::...
            </Text>
          </Box>
        </Center>

        <Footer />
      </div>
    </>
  );
};

export default Disclimer;
