import React, { useEffect, useState } from "react";
import {
  Flex,
  Card,
  Image,
  Heading,
  Text,
  Button,
  HStack,
  Container,
  Box,
  Select,
  Center,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import "./Screen.css";
import { FaStar } from "react-icons/fa6";
import { FaLongArrowAltRight, FaTelegram } from "react-icons/fa";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt } from "react-icons/fa";
import { TiThMenu } from "react-icons/ti";
import { Helmet } from "react-helmet";

const ITEMS_PER_PAGE = 20;

const colors = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "teal",
  "cyan",
  "yellow",
];

const Movies = () => {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [showAd, setShowAd] = useState(true);
  const MAX_PAGE_DISPLAY = 3;

  const navigate = useNavigate();

  const getMovies = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/movies/get_files`);
      if (response.status === 200) {
        setMovies(response.data);
        setFilteredMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    getMovies();
  }, []);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      navigate(`/category-filter-movies/${selectedCategory}`);
    }
  };

  useEffect(() => {
    const newIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    setStartIndex(newIndex);
  }, [currentPage]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredResults = movies.filter((movie) =>
      movie.movieName.toLowerCase().includes(searchValue)
    );
    setFilteredMovies(filteredResults);
    setCurrentPage(1); // Reset currentPage when search term changes
  };

  const generatePageNumbers = (totalPages, currentPage) => {
    window.scrollTo(0, 0);

    const pages = [];
    const maxVisiblePages = 5; // Number of page links to show

    // Always show the first page
    if (currentPage > 1) {
      pages.push(1);
    }

    // Determine the start and end page numbers
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 2);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages - 1);

    // Adjust startPage if we're close to the end
    if (endPage >= totalPages - 1) {
      startPage = Math.max(totalPages - maxVisiblePages, 2);
      endPage = totalPages - 1;
    }

    // Add the page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Always show the last page
    if (currentPage < totalPages - 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const handlePagination = () => {
    const totalPages = Math.ceil(filteredMovies.length / ITEMS_PER_PAGE);
    const pages = [];

    // Calculate the range of pages to display
    let startPage = Math.max(2, currentPage - Math.floor(MAX_PAGE_DISPLAY / 2));
    let endPage = Math.min(
      totalPages - 1,
      currentPage + Math.floor(MAX_PAGE_DISPLAY / 2)
    );

    // Adjust the range if the current page is near the start or end
    if (currentPage <= Math.floor(MAX_PAGE_DISPLAY / 2)) {
      endPage = Math.min(totalPages - 1, MAX_PAGE_DISPLAY);
    }
    if (currentPage + Math.floor(MAX_PAGE_DISPLAY / 2) >= totalPages) {
      startPage = Math.max(2, totalPages - MAX_PAGE_DISPLAY + 1);
    }

    // Add the first page and the "previous" button
    if (totalPages > 1) {
      pages.push(
        <Button
          key="prev"
          onClick={() => handleClick("prev")}
          isDisabled={currentPage === 1}
          colorScheme="blue"
        >
          Previous
        </Button>
      );

      pages.push(
        <Button
          key={1}
          onClick={() => setCurrentPage(1)}
          colorScheme={currentPage === 1 ? "blue" : "gray"}
        >
          1
        </Button>
      );
    }

    // Add the pages between the first and last page
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => setCurrentPage(i)}
          colorScheme={currentPage === i ? "blue" : "gray"}
        >
          {i}
        </Button>
      );
    }

    // Add the last page and the "next" button
    if (totalPages > 1) {
      if (endPage < totalPages - 1) {
        pages.push(
          <Text key="ellipsis" color="white">
            ........
          </Text>
        );
      }
      pages.push(
        <Button
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
          colorScheme={currentPage === totalPages ? "blue" : "gray"}
        >
          {totalPages}
        </Button>
      );

      pages.push(
        <Button
          key="next"
          onClick={() => handleClick("next")}
          isDisabled={currentPage === totalPages}
          colorScheme="blue"
        >
          Next
        </Button>
      );
    }

    return pages;
  };

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredMovies.length / ITEMS_PER_PAGE);
  const pageNumbers = generatePageNumbers(totalPages, currentPage);

  const displayedMovies = filteredMovies.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handleCardClick = () => {
    window.open("https://loanwaystore.com");
    // window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5");
    // setTimeout(() => {
    //   window.open("https://loanwaystore.com", "_blank");
    // }, 0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAd(false);
    }, 45000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseAd = () => {
    setShowAd(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MovieHub4U | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="MovieHub4u is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="MoviesFlix download,
MoviesFlix Bollywood,
MoviesFlix Hollywood,
MoviesFlix HD movies,
MoviesFlix TV shows,
MoviesFlix web series,
MoviesFlix 1080p,
MoviesFlix 720p,
MoviesFlix latest movies,
MoviesFlix new releases,
MoviesFlix free download,
MoviesFlix dubbed movies,
MoviesFlix Hindi movies,
MoviesFlix English movies,
MoviesFlix Tamil movies,
MoviesFlix Telugu movies,
MoviesFlix Punjabi movies,
MoviesFlix Marathi movies,
MoviesFlix South Indian movies,
MoviesFlix action movies,
MoviesFlix adventure movies,
MoviesFlix comedy movies,
MoviesFlix drama movies,
MoviesFlix horror movies,
MoviesFlix thriller movies,
MoviesFlix sci-fi movies,
MoviesFlix romance movies,
MoviesFlix family movies,
MoviesFlix animation movies,
MoviesFlix fantasy movies,
MoviesFlix documentary,
MoviesFlix crime movies,
MoviesFlix war movies,
MoviesFlix historical movies,
MoviesFlix mystery movies,
MoviesFlix box office hits,
MoviesFlix top rated movies,
MoviesFlix full movies,
MoviesFlix old movies,
MoviesFlix classics,
MoviesFlix trending movies,
MoviesFlix blockbuster movies,
MoviesFlix movie reviews,
MoviesFlix streaming,
MoviesFlix online watch,
MoviesFlix movie trailers,
MoviesFlix subtitles,
MoviesFlix dual audio,
MoviesFlix BluRay movies,
MoviesFlix torrents,
Latest movies,
New movie releases,
Free movie download,
HD movies online,
Watch movies online,
Full movies,
Best movies of 2023,
Best movies of 2024,
Top rated movies,
Popular movies,
Action movies,
Horror movies,
Comedy movies,
Romantic movies,
Thriller movies,
Sci-fi movies,
Family movies,
Adventure movies,
Animation movies,
Fantasy movies,
Drama movies,
Bollywood movies,
Hollywood movies,
Hindi movies,
English movies,
Dubbed movies,
Tamil movies,
Telugu movies,
Punjabi movies,
South Indian movies,
Classic movies,
Old movies,
Blockbuster movies,
Award-winning movies,
Movies with subtitles,
Kids movies,
Documentaries,
Movie trailers,
Movie reviews,
Movie streaming sites,
Online movie watch,
Download full movies,
BluRay movies,
1080p movies,
720p movies,
Dual audio movies,
Hindi dubbed movies,
Movie download sites,
Latest Hindi movies,
New Hollywood movies,
Top IMDb movies,"
        />
        <link rel="canonical" href="https://moviehub4u.org/" />
      </Helmet>
      <hr style={{ color: "white" }} />
      <Box>
        <nav
          className="navbar navbar-expand-lg bg-body-black"
          style={{ background: "black" }}
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ color: "white", marginBottom: "7px" }}
            >
              <TiThMenu />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <span className="navbar-nav me-auto mb-1 mb-lg-0">
                <span className="nav-item">
                  <Box
                    width="100%"
                    display="flex"
                    flexWrap="wrap"
                    overflowX="auto"
                  >
                    {categories?.map((cat, index) => (
                      <div key={cat.id}>
                        <Center>
                          <Button
                            as={Link}
                            to={`/category-filter-movies/${cat.catName}`}
                            marginRight={2}
                            colorScheme={colors[index % colors.length]}
                            margin={1}
                          >
                            {cat.catName}
                          </Button>
                        </Center>
                      </div>
                    ))}{" "}
                  </Box>
                </span>
              </span>
            </div>
            <Link to="/">
              <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="text"
                  placeholder="Search movies..."
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ width: "100%", marginBottom: "20px" }}
                />
              </form>
            </Link>
          </div>
        </nav>{" "}
      </Box>
      <div
        style={{
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
        }}
      >
        <div className="alert1" id="myDIV">
          <span
            className="closebtn"
            onclick="if (!window.__cfRLUnblockHandlers) return false; this.parentElement.style.display='none';"
          >
            ×
          </span>
          <center>
            <font size="3px">
              <b style={{ fontWeight: 400, color: "white" }}>
                Welcome to the <b className="hd">Movie</b>
                <b className="movie">Hub</b>
                <b className="hub">4U</b> 👉
                <b>
                  <a
                    href="https://telegram.me/moviehub4u143"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Join Our Telegram Channel
                  </a>
                </b>
                | 🔖 Remember our domain <b className="hd">Movie</b>
                <b className="movie">Hub</b>
                <b className="hub">4U</b>
                <b></b> to visit us.
              </b>
            </font>
          </center>
        </div>

        <hr style={{ color: "white" }} />

        <Text textAlign="center" color="white" background="#fe7c3d">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MovieHub4U: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
      </div>

      <Center>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-9">
            <Box display="flex" marginTop="10px" justifyContent="center">
              <FaFireAlt
                style={{
                  color: "white",
                  width: "40px",
                  height: "40px",
                  marginRight: "20px",
                }}
              />
              <Text color="white" fontFamily="lemon" fontSize="xx-large">
                Latest Releases
              </Text>
            </Box>
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              onClick={handleCardClick}
            >
              {" "}
              {displayedMovies.map((mov, index) => (
                <Card
                  key={index}
                  background="#252526"
                  borderLeft="3px solid rgb(255, 0, 0)"
                  borderRight="3px solid rgb(0, 128, 0)"
                  borderTop="3px solid rgb(255, 255, 0)"
                  borderBottom="3px solid rgb(255, 255, 0)"
                  m={{ base: 1, md: 1 }}
                  className="custom-card"
                  cursor="pointer"
                  onClick={() => navigate(`/movies-details/${mov._id}`)}
                >
                  <Image
                    className="banner-image"
                    src={mov.bannerImg}
                    alt="movie banner"
                    width="100%"
                    height="280px"
                    borderRadius="lg"
                  />
                  <Text
                    padding={2}
                    fontSize="large"
                    className="title"
                    color="white"
                    textAlign="center"
                    fontWeight="600"
                  >
                    {mov.movieName.slice(0, 150)}
                  </Text>
                </Card>
              ))}
            </Flex>
            <HStack spacing="24px" justify="center" margin="20px">
              {handlePagination()}
            </HStack>
          </div>
          <div className="col-md-3">
            <Box width="100%">
              <Text fontSize="x-large" color="white" textAlign="center">
                Search Here!!!
              </Text>
              <Center>
                <form className="d-flex" role="search">
                  <input
                    className="form-control me-2"
                    type="text"
                    placeholder="Search movies..."
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ width: "95%", marginBottom: "20px" }}
                  />
                </form>
                {/* <img src="hdhub4ulogo.png" alt="Logo" style={{ width: "200px" }} /> */}
              </Center>
            </Box>
            <Box width="100%">
              <Text fontSize="x-large" color="white" textAlign="center">
                Category !!!
              </Text>
              <Center>
                <Select
                  placeholder="Select Category"
                  color="white"
                  background="black"
                  onChange={handleChange}
                  width="95%"
                >
                  {categories.length > 0 &&
                    categories.map((cat) => (
                      <option
                        style={{ color: "white", background: "black" }}
                        key={cat.id}
                        value={cat.catName}
                      >
                        {cat.catName}
                      </option>
                    ))}
                </Select>
              </Center>
            </Box>
          </div>
        </div>
      </Center>
      {/* Footer Ad */}
      <Box
        as="footer"
        position="fixed"
        bottom="0"
        width="100%"
        background="black"
        padding="10px"
        textAlign="center"
      >
        <Box position="absolute" top="10px" right="10px">
          <Link
            to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236 "
            target="_blank"
          >
            <Button colorScheme="" onClick={() => setShowAd(false)}>
              X
            </Button>
          </Link>
        </Box>
        {showAd && (
          <Link
            to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236"
            target="_blank"
          >
            <Box>
              <Image
                src="https://refpa7921972.top/img/AdAgent_1/d0daa1d5-d517-455b-986b-3b49e7ab67a3.gif"
                alt="Ad Banner"
                width="100%"
                height="auto"
                className="betclass"
                onClick={() => setShowAd(false)}
              />
            </Box>
          </Link>
        )}
      </Box>
    </>
  );
};

export default Movies;
