import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { Box, Button, Center, Text, useToast } from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt, FaOtter } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as mod from "./../../url";
import { useNavigate } from "react-router-dom";

const MovieRequest = () => {
  const [name, setName] = useState("");
  const [movies, setMovies] = useState("");
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const submitHandler = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.post(
        `${mod.api_url}/request/submit_details`,
        { name, movies },
        config
      );
      const data = response.data;
      if (data && data.success) {
        toast({
          title: "movie request  Successful.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/");
      } else {
        toast({
          title: "Invalid credentials.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.error("movie request Error:", error);
      toast({
        title: "Error During movie request",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <MovieSliders />
        <Text
          textAlign="center"
          color="red"
          fontFamily="lemon"
          fontWeight="600"
        >
          Just Remember 'MoviesHub4u' to visit us if Blocked
        </Text>
        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MovieHub4U: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <Box display="flex" marginTop="10px" justifyContent="center">
          <FaFireAlt
            style={{
              color: "white",
              width: "40px",
              height: "40px",
              marginRight: "20px",
            }}
          />
          <Text color="white" fontFamily="lemon" fontSize="xx-large">
            Request Your Movies
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="red"
            fontSize="large"
            fontWeight="500"
            textAlign="center"
          >
            ...::MoviesHub4u::...
          </Text>
          <Text
            color="green"
            fontSize="large"
            fontWeight="500"
            textAlign="center"
          >
            Kindly search on our site before making a request..
          </Text>
          <Text
            color="blue"
            fontSize="large"
            fontWeight="500"
            textAlign="center"
          >
            Make Request Down in Comments !!!
          </Text>
        </Box>

        <div
          className="col-md-12"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Center
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Input
              placeholder="Your Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              style={{ marginBottom: "10px", width: "70%", color: "white" }}
            />

            <Textarea
              placeholder="Enter your movies name here"
              onChange={(e) => setMovies(e.target.value)}
              value={movies}
              style={{ width: "70%", marginBottom: "10px", color: "white" }}
            />

            <Button
              width="30%"
              marginBottom="15px"
              colorScheme="green"
              onClick={submitHandler}
            >
              Request
            </Button>
          </Center>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default MovieRequest;
