import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import storage from "./../firebase";
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";
import {
  ref,
  getDownloadURL,
  getMetadata,
  uploadBytesResumable,
} from "firebase/storage";
import { ChevronDownIcon } from "@chakra-ui/icons";

const languages = [
  { lang: "Hindi" },
  { lang: "English" },
  { lang: "Tamil" },
  { lang: "Spanish" },
  { lang: "French" },
  { lang: "German" },
  { lang: "Chinese" },
  { lang: "Japanese" },
  { lang: "Russian" },
  { lang: "Arabic" },
];

const PostMovies = () => {
  const [categories, setCategories] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [movieName, setMovieName] = useState("");
  const [language, setLanguage] = useState([]);
  const [rating, setRating] = useState("");
  const [category, setCategory] = useState([]);
  const [actors, setActors] = useState("");
  const [description, setDescription] = useState("");
  const [review, setReview] = useState("");
  const [trailer, setTrailer] = useState("");
  const [videoName, setVideoName] = useState("");
  const [bannerImg, setBannerImg] = useState(null);
  const [screenShotImg, setScreenShotImg] = useState([]);
  const [video, setVideo] = useState([]);
  const [imgperc, setImgper] = useState(0);
  const [videoPer, setVideoPer] = useState(0);
  const [inputs, setInputs] = useState({});

  const handleLanguageChange = (selected) => {
    setLanguage(selected);
  };
  const handleCategoryChange = (selected) => {
    setCategory(selected);
  };

  useEffect(() => {
    if (video.length > 0) {
      uploadFiles(video, "videos");
    }
  }, [video]);

  useEffect(() => {
    if (bannerImg) {
      uploadFile(bannerImg, "bannerImg")
        .then((url) => {
          setInputs((prev) => ({
            ...prev,
            bannerImg: url,
          }));
        })
        .catch((error) => {
          console.error("Error uploading banner image:", error);
        });
    }
  }, [bannerImg]);

  useEffect(() => {
    if (screenShotImg.length > 0) {
      uploadFiles(screenShotImg, "screenshots");
    }
  }, [screenShotImg]);

  const uploadFiles = async (files, fileType) => {
    try {
      const uploadPromises = files?.map((file) => uploadFile(file, fileType));
      const downloadURLs = await Promise.all(uploadPromises);
      setInputs((prev) => ({
        ...prev,
        [fileType]: downloadURLs,
      }));
    } catch (error) {
      console.error("Failed to upload files", error);
      throw error;
    }
  };

  const uploadFile = (file, fileType) => {
    return new Promise((resolve, reject) => {
      const folder = fileType === "bannerImg" ? "images/" : `${fileType}/`;
      const fileName = new Date().getTime() + file.name;

      const storageRef = ref(storage, folder + fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (fileType === "bannerImg") {
            setImgper(Math.round(progress));
          } else if (fileType === "videos") {
            setVideoPer(Math.round(progress));
          }
        },
        (error) => {
          console.error("Upload error:", error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const metadata = await getMetadata(uploadTask.snapshot.ref);
            const fileSize = metadata.size; // file size in bytes
            console.log("File available at", downloadURL, "Size:", fileSize);
            resolve({ downloadURL, fileSize });
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  const handleChangelang = (e) => {
    setLanguage(e.target.value);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "bannerImg") {
      setBannerImg(files[0]);
    } else if (name === "screenShotImg") {
      setScreenShotImg(Array.from(files));
    } else if (name === "video") {
      setVideo(Array.from(files));
    }
  };

  const submitHandler = async () => {
    setLoading(true);

    if (
      !movieName ||
      !language.length === 0 ||
      !rating ||
      !category.length === 0 ||
      !actors ||
      !description ||
      !review ||
      !trailer ||
      !inputs.bannerImg ||
      !inputs.screenshots ||
      !inputs.videos
    ) {
      toast({
        title: "Please fill all the blanks.",
        status: "warning",
        duration: 500,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("movieName", movieName);
    formData.append("language", JSON.stringify(language));
    formData.append("rating", rating);
    formData.append("category", JSON.stringify(category));
    formData.append("actors", actors);
    formData.append("description", description);
    formData.append("review", review);
    formData.append("trailer", trailer);
    formData.append("bannerImg", inputs.bannerImg.downloadURL);

    inputs.screenshots.forEach((screenshot, index) => {
      formData.append(
        "screenShotImg",
        JSON.stringify({
          url: screenshot.downloadURL,
        })
      );
    });

    inputs.videos.forEach((videoUrl, index) => {
      formData.append(
        "videos",
        JSON.stringify({
          url: videoUrl.downloadURL,
          size: videoUrl.fileSize,
          videoName: videoName,
        })
      );
    });

    try {
      const { data } = await axios.post(
        `${mod.api_url}/movies/create_details`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast({
        title: "Movie posted successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      navigate("/");
      setMovieName("");
      setLanguage([]);
      setRating("");
      setCategory([]);
      setActors("");
      setDescription("");
      setReview("");
      setTrailer("");
      setBannerImg(null);
      setScreenShotImg([]);
      setVideo([]);
    } catch (error) {
      console.error("Post Movie Error:", error);
      toast({
        title: "Error during post Movie.",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="main"
        style={{ background: "black", height: "100%", padding: "10px" }}
      >
        <Box>
          <Text
            textAlign="center"
            color="white"
            fontSize="xx-large"
            fontWeight="700"
          >
            Post Movies
          </Text>
        </Box>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movies Name
            </FormLabel>
            <Input
              placeholder="Enter Movies Name"
              color="white"
              marginTop={3}
              onChange={(e) => setMovieName(e.target.value)}
              value={movieName}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Select Movie Language
            </FormLabel>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                background="white"
                color="black"
                width="100%"
              >
                {language.length > 0
                  ? `Selected Languages (${language.length})`
                  : "Select Languages"}
              </MenuButton>
              <MenuList background="black" color="white">
                <CheckboxGroup value={language} onChange={handleLanguageChange}>
                  <Stack spacing={3} padding={3}>
                    {languages.map((lang, index) => (
                      <Checkbox key={index} value={lang.lang}>
                        {lang.lang}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </MenuList>
            </Menu>
          </div>
        </div>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movies IMDB Rating
            </FormLabel>
            <Input
              placeholder="Enter Movies IMDB Rating"
              color="white"
              marginTop={3}
              onChange={(e) => setRating(e.target.value)}
              value={rating}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Select Movie Category
            </FormLabel>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                background="white"
                color="black"
                width="100%"
              >
                {category.length > 0
                  ? `Selected category (${category.length})`
                  : "Select category"}
              </MenuButton>
              <MenuList background="black" color="white">
                <CheckboxGroup value={category} onChange={handleCategoryChange}>
                  <Stack spacing={3} padding={3}>
                    {categories.map((cat) => (
                      <Checkbox
                        key={cat._id}
                        style={{ color: "white", backgroundColor: "black" }}
                        value={cat.catName}
                      >
                        {cat.catName}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </MenuList>
            </Menu>
          </div>
        </div>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movies Star Name
            </FormLabel>
            <Input
              placeholder="Enter Movies Star Name"
              color="white"
              marginTop={3}
              onChange={(e) => setActors(e.target.value)}
              value={actors}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movies Trailer Youtube Link
            </FormLabel>
            <Input
              placeholder="Movie Trailer YouTube Link"
              color="white"
              marginTop={3}
              onChange={(e) => setTrailer(e.target.value)}
              value={trailer}
            />
          </div>
        </div>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movies StoryLine
            </FormLabel>
            <Textarea
              placeholder="Enter Movie StoryLine"
              color="white"
              marginTop={3}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movies Review
            </FormLabel>
            <Textarea
              placeholder="Enter Movie Review"
              color="white"
              marginTop={3}
              onChange={(e) => setReview(e.target.value)}
              value={review}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Enter Movie or Web-Show Name
            </FormLabel>
            <Textarea
              placeholder="Enter video Name"
              color="white"
              marginTop={3}
              onChange={(e) => setVideoName(e.target.value)}
              value={videoName}
            />
          </div>
        </div>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Select Movie Banner
            </FormLabel>
            <Text color="green">
              {" "}
              {imgperc > 0 && "uploading:" + imgperc + "%"}
            </Text>
            <Input
              placeholder="Select Movie Banner"
              color="white"
              type="file"
              p={1}
              name="bannerImg"
              onChange={handleFileChange}
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Select Movie Screen Shots
            </FormLabel>

            <Input
              placeholder="Select Movie Screen Shots"
              color="white"
              type="file"
              multiple
              p={1}
              name="screenShotImg"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <FormLabel color="white" marginTop={3}>
              Select Your Movie Mp4 File
            </FormLabel>
            <Text color="green">
              {" "}
              {videoPer > 0 && "uploading:" + videoPer + "%"}
            </Text>
            <br />
            <Input
              placeholder="Select Movie MP4 File"
              color="white"
              type="file"
              p={1}
              name="video"
              onChange={handleFileChange}
              multiple
            />
          </div>
          <div className="col-md-6">
            <FormLabel color="black" marginTop={3}>
              .
            </FormLabel>
            <Center>
              <Button
                colorScheme="green"
                onClick={submitHandler}
                // isLoading={true}
              >
                Post Movie
              </Button>
            </Center>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PostMovies;
