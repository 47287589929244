import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TiThMenu } from "react-icons/ti";
import "./Screen.css";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as mod from "./../../url";
import axios from "axios";

const colors = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "teal",
  "cyan",
  "yellow",
];

const Category = () => {
  const [categories, setCategories] = useState([]);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCardClick = () => {
    window.open("https://stake.com/?c=VvyhynH8");
    window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5", "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-black"
        style={{ background: "black" }}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ color: "white" }}
          >
            <TiThMenu />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <span className="navbar-nav me-auto mb-1 mb-lg-0">
              <span className="nav-item" onClick={handleCardClick}>
                <Box
                  width="100%"
                  display="flex"
                  flexWrap="wrap"
                  overflowX="auto"
                >
                  {categories?.map((cat, index) => (
                    <div key={cat.id}>
                      <Button
                        as={Link}
                        to={`/category-filter-movies/${cat.catName}`}
                        marginRight={2}
                        colorScheme={colors[index % colors.length]}
                        margin={1}
                      >
                        {cat.catName}
                      </Button>
                    </div>
                  ))}
                </Box>
              </span>
            </span>
          </div>
          <Link to="/">
            <img src="hdhub4ulogo.png" alt="Logo" style={{ width: "200px" }} />
          </Link>
        </div>
      </nav>
      <hr />
      <div
        style={{
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
        }}
      ></div>
      <Box display="flex" marginTop="10px" justifyContent="center">
        <FaFireAlt
          style={{
            color: "yellow",
            width: "40px",
            height: "40px",
            marginRight: "20px",
          }}
        />
        <Text color="orange" fontFamily="lemon" fontSize="xx-large">
          Latest Releases
        </Text>
      </Box>
    </>
  );
};

export default Category;
