import React, { useEffect, useRef } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { Box, Button, Center, Image, Text } from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt, FaOtter } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import Category from "../Screens/Category";

const Howtodownload = () => {
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <MovieSliders />
        <Category />
        <div
          style={{
            borderTop: "1px solid white",
            borderBottom: "1px solid white",
          }}
        >
          <div className="alert1" id="myDIV">
            <span
              className="closebtn"
              onclick="if (!window.__cfRLUnblockHandlers) return false; this.parentElement.style.display='none';"
            >
              ×
            </span>
            <center>
              <font size="3px">
                <b style={{ fontWeight: 400, color: "white" }}>
                  Welcome to the <b className="hd">Movie</b>
                  <b className="movie">Hub</b>
                  <b className="hub">4U</b> 👉
                  <b>
                    <a
                      href="https://telegram.me/moviehub4u143"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "blue" }}
                    >
                      Join Our Telegram Channel
                    </a>
                  </b>
                  | 🔖 Remember our domain <b className="hd">Movie</b>
                  <b className="movie">Hub</b>
                  <b className="hub">4U</b>
                  <b></b> to visit us.
                </b>
              </font>
            </center>
          </div>

          <hr style={{ color: "white" }} />

          <Text textAlign="center" color="white" background="#fe7c3d">
            <marquee className="blinking-text">
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                <BiSolidCameraMovie />
                "MovieHub4U: Your ultimate destination for the latest movies,
                streaming, reviews, trailers, and more. Explore, watch, and
                enjoy unlimited entertainment here!"
              </span>
            </marquee>
          </Text>
        </div>

        <Box display="flex" marginTop="10px" justifyContent="center">
          <FaFireAlt
            style={{
              color: "white",
              width: "40px",
              height: "40px",
              marginRight: "20px",
            }}
          />
          <Text color="white" fontFamily="lemon" fontSize="xx-large">
            How to Download Your Movies
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="white"
            fontSize="x-large"
            fontWeight="600"
            textAlign="center"
            borderTop="1px solid #252525"
            borderBottom="1px solid #252525"
          >
            Watch The Video, To Easily Download Any Content From Moviehub4u Site
            🙂
          </Text>
          <Center>
            <Box width="95%">
              <video ref={videoRef} width="640" height="360" controls>
                <source
                  src="/WhatsApp Video 2024-07-18 at 13.28.39_bc0dda7d.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Center>

          <Center>
            <Box border="1px solid white" width="80%">
              <Text
                color="red"
                fontSize="xx-large"
                fontWeight="700"
                textAlign="center"
                borderTop="1px solid #252525"
                borderBottom="1px solid #252525"
                marginTop="10px"
              >
                ...::MoviesHub4u::...
              </Text>

              <Text
                marginTop="20px"
                color="white"
                fontSize="large"
                textAlign="center"
              >
                <span>👉</span>First of all choose the movie on MovieHub4u site
                and then scroll down and click on the download button.{" "}
                <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> After clicking on the download button, the
                "click here" button will appear, click on it, then after some
                time click on the "Click Here and Wait..." button.{" "}
                <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> After that again click on the "Click here"
                button and wait for some time. <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> After that click on the "Click Here and Wait..."
                button. <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> After that click on the "Click Here and Wait..."
                button and wait for some time. <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> After that click on the "Click Here and Wait..."
                button. <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> After this you will see 4 or 5 options, click on
                them. After this you will reach the movie download page.{" "}
                <span>👈</span>
              </Text>

              <Text
                color="white"
                fontSize="large"
                textAlign="center"
                marginTop="10px"
              >
                <span>👉</span> Next you can download the movie from the link
                given below. <span>👈</span>
              </Text>
            </Box>
          </Center>

          <Text
            color="red"
            fontSize="xx-large"
            fontWeight="700"
            textAlign="center"
            borderTop="1px solid #252525"
            borderBottom="1px solid #252525"
            marginTop="10px"
          >
            ...::MoviesHub4u::...
          </Text>
        </Box>

        <Footer />
      </div>
    </>
  );
};

export default Howtodownload;
