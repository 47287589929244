import React, { useEffect } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { Box, Button, Center, Image, Text } from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import { FaFireAlt, FaOtter } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Joingroup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <MovieSliders />
        <Text
          textAlign="center"
          color="red"
          fontFamily="lemon"
          fontWeight="600"
        >
          Just Remember 'MoviesHub4u' to visit us if Blocked
        </Text>
        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MovieHub4U: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <Box display="flex" marginTop="10px" justifyContent="center">
          <FaFireAlt
            style={{
              color: "white",
              width: "40px",
              height: "40px",
              marginRight: "20px",
            }}
          />
          <Text color="white" fontFamily="lemon" fontSize="xx-large">
            Join Our Group !
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="red"
            fontSize="xx-large"
            fontWeight="500"
            textAlign="center"
          >
            ...::MoviesHub4u::...
          </Text>
        </Box>
        <Box display="block" marginTop="10px" justifyContent="center">
          <Text
            color="white"
            fontSize="x-large"
            fontWeight="600"
            textAlign="center"
            borderTop="1px solid #252525"
            borderBottom="1px solid #252525"
          >
            Join Our Telegram Channel
          </Text>

          <div className="row" style={{ width: "100%" }}>
            <Center>
              <Link to="https://telegram.me/moviehub4u143" target="_blank">
                <img
                  src="download.jpeg"
                  style={{
                    width: "250px",
                    height: "80px",
                    borderRadius: "20px",
                  }}
                />

                <Text
                  textAlign="center"
                  color="blue"
                  fontSize="x-large"
                  marginTop="25px"
                >
                  Click To Join
                </Text>
              </Link>
            </Center>
          </div>

          <Text
            color="red"
            fontSize="xx-large"
            fontWeight="700"
            textAlign="center"
            borderTop="1px solid #252525"
            borderBottom="1px solid #252525"
          >
            ...::MoviesHub4u::...
          </Text>
        </Box>

        <Footer />
      </div>
    </>
  );
};

export default Joingroup;
