import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaFacebookF, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleCardClick = () => {
    window.open("https://loanwaystore.com", "_blank");
    setTimeout(() => {
      window.open("https://loanwaystore.com", "_blank");
    }, 3000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="row"
        style={{
          width: "100%",
          borderTop: "1px solid white",
          marginTop: "10px",
          backgroundColor: "black",
        }}
      >
        <div className="col-md-2">
          <Link to="/disclimer">
            <Text
              fontSize="large"
              color="blue"
              marginTop={2}
              textAlign="center"
              cursor="pointer"
              onClick={handleCardClick}
            >
              Disclimer
            </Text>
          </Link>
        </div>
        <div className="col-md-2">
          <Link to="/join-group">
            <Text
              fontSize="large"
              marginTop={2}
              color="blue"
              textAlign="center"
              cursor="pointer"
              onClick={handleCardClick}
            >
              Join Our Group
            </Text>
          </Link>
        </div>
        <div className="col-md-2">
          <Link to="/how-to-download">
            <Text
              fontSize="large"
              marginTop={2}
              color="blue"
              textAlign="center"
              cursor="pointer"
              onClick={handleCardClick}
            >
              How to Download
            </Text>
          </Link>
        </div>
        <div className="col-md-2">
          <Link to="/privacy-policy">
            <Text
              fontSize="large"
              color="blue"
              textAlign="center"
              cursor="pointer"
              marginTop={2}
              onClick={handleCardClick}
            >
              Privacy Policy
            </Text>
          </Link>
        </div>

        <div className="col-md-2" style={{ textAlign: "right" }}>
          <Link to="https://www.instagram.com/invites/contact/?igsh=1gh8e6xn09n2x&utm_content=vblt2qw ">
            <Text
              fontSize="large"
              color="pink"
              textAlign="center"
              cursor="pointer"
              display="inline-block"
              marginRight="10px"
              marginTop={2}
              padding={1}
              border="1px solid white"
            >
              <FaSquareInstagram style={{ width: "20px", height: "auto" }} />
            </Text>
          </Link>

          <Link to="https://www.facebook.com/MovieHub4u143/">
            <Text
              fontSize="large"
              color="blue"
              textAlign="center"
              cursor="pointer"
              display="inline-block"
              marginRight="10px"
              border="1px solid white"
              marginTop={2}
              padding={1}
            >
              <FaFacebookF style={{ width: "20px", height: "auto" }} />
            </Text>
          </Link>

          <Link to="https://youtube.com/@moviehub4u-org?si=SwgvoHJk_WXvNo43">
            <Text
              fontSize="large"
              color="red"
              textAlign="center"
              cursor="pointer"
              display="inline-block"
              padding={1}
              border="1px solid white"
            >
              <FaYoutube style={{ width: "20px", height: "auto" }} />
            </Text>
          </Link>
        </div>
      </div>
      <div
        className="row"
        style={{
          width: "100%",
          backgroundColor: "black",
        }}
      >
        <div className="col-md-12 " style={{ width: "100%" }}>
          <Text
            fontSize="large"
            color="white"
            textAlign="center"
            cursor="pointer"
          >
            2024 © Moviehub4u | All Rights Reserved
          </Text>
        </div>
      </div>
    </>
  );
};

export default Footer;
