import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { FaFireAlt } from "react-icons/fa";
import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import Footer from "../Footer/Footer";
import Category from "../Screens/Category";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";
import { Bars } from "react-loader-spinner";
import { Helmet } from "react-helmet";

const ITEMS_PER_PAGE = 12;

const CategoryMovie = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [movies, setMovies] = useState(null);
  const navigate = useNavigate();

  const params = useParams();
  const id = params.catName;
  // console.log(params.catName, 'kkkkkk')

  const handleClick = (direction) => {
    if (
      direction === "next" &&
      currentPage < Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const filteredMovies = movies?.filter((movie) =>
    movie?.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentData = filteredMovies?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const getMovies = async () => {
    try {
      console.log("Fetching movies details for ID:", id);
      const response = await axios.get(`${mod.api_url}/movies/findByCat/${id}`);
      if (response.status === 200) {
        console.log("Movie details fetched successfully:", response.data);
        setMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getMovies();
    }
  }, [id]);

  if (!movies) {
    return (
      <div
        className="container-fluid d-flex justify-content-center align-items-center vh-100"
        style={{ background: "black" }}
      >
        <div className="row">
          <div className="col-md-12 text-center">
            <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <Link to="/">
              <Button colorScheme="blue" marginTop={10}>
                Go Back
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MovieHub4U | Download All Bollywood and Hollywood Movies, South Indian
          Movies ...
        </title>
        <meta
          name="description"
          content="MovieHub4u is a platform offering downloads of Bollywood, Hollywood, and South Indian movies. It provides a variety of films across genres, making it a go-to destination for movie enthusiasts."
        />
        <meta
          name="keywords"
          content="MoviesFlix download,
MoviesFlix Bollywood,
MoviesFlix Hollywood,
MoviesFlix HD movies,
MoviesFlix TV shows,
MoviesFlix web series,
MoviesFlix 1080p,
MoviesFlix 720p,
MoviesFlix latest movies,
MoviesFlix new releases,
MoviesFlix free download,
MoviesFlix dubbed movies,
MoviesFlix Hindi movies,
MoviesFlix English movies,
MoviesFlix Tamil movies,
MoviesFlix Telugu movies,
MoviesFlix Punjabi movies,
MoviesFlix Marathi movies,
MoviesFlix South Indian movies,
MoviesFlix action movies,
MoviesFlix adventure movies,
MoviesFlix comedy movies,
MoviesFlix drama movies,
MoviesFlix horror movies,
MoviesFlix thriller movies,
MoviesFlix sci-fi movies,
MoviesFlix romance movies,
MoviesFlix family movies,
MoviesFlix animation movies,
MoviesFlix fantasy movies,
MoviesFlix documentary,
MoviesFlix crime movies,
MoviesFlix war movies,
MoviesFlix historical movies,
MoviesFlix mystery movies,
MoviesFlix box office hits,
MoviesFlix top rated movies,
MoviesFlix full movies,
MoviesFlix old movies,
MoviesFlix classics,
MoviesFlix trending movies,
MoviesFlix blockbuster movies,
MoviesFlix movie reviews,
MoviesFlix streaming,
MoviesFlix online watch,
MoviesFlix movie trailers,
MoviesFlix subtitles,
MoviesFlix dual audio,
MoviesFlix BluRay movies,
MoviesFlix torrents,
Latest movies,
New movie releases,
Free movie download,
HD movies online,
Watch movies online,
Full movies,
Best movies of 2023,
Best movies of 2024,
Top rated movies,
Popular movies,
Action movies,
Horror movies,
Comedy movies,
Romantic movies,
Thriller movies,
Sci-fi movies,
Family movies,
Adventure movies,
Animation movies,
Fantasy movies,
Drama movies,
Bollywood movies,
Hollywood movies,
Hindi movies,
English movies,
Dubbed movies,
Tamil movies,
Telugu movies,
Punjabi movies,
South Indian movies,
Classic movies,
Old movies,
Blockbuster movies,
Award-winning movies,
Movies with subtitles,
Kids movies,
Documentaries,
Movie trailers,
Movie reviews,
Movie streaming sites,
Online movie watch,
Download full movies,
BluRay movies,
1080p movies,
720p movies,
Dual audio movies,
Hindi dubbed movies,
Movie download sites,
Latest Hindi movies,
New Hollywood movies,
Top IMDb movies,"
        />
        <link rel="canonical" href="https://moviehub4u.org/" />
      </Helmet>
      <div className="main" style={{ background: "black" }}>
        <Navbar />
        <hr style={{ color: "white" }} />
        <MovieSliders />
        <hr style={{ color: "white" }} />
        <Text
          textAlign="center"
          color="red"
          fontFamily="lemon"
          fontWeight="600"
        >
          Just Remember 'MoviesHub4u' to visit us if Blocked
        </Text>
        <Text textAlign="center" color="white">
          <marquee className="blinking-text">
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <BiSolidCameraMovie />
              "MovieHub4U: Your ultimate destination for the latest movies,
              streaming, reviews, trailers, and more. Explore, watch, and enjoy
              unlimited entertainment here!"
            </span>
          </marquee>
        </Text>
        <hr style={{ color: "white" }} />

        <Text
          color="blue"
          fontSize="x-large"
          fontWeight="600"
          textAlign="center"
          padding={3}
        >
          ...::Movie-By-Category::...
        </Text>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-12">
            <Flex flexWrap="wrap" justifyContent="center">
              {movies.map((movie) => (
                <Card
                  key={movie.id}
                  m={{ base: 1, md: 1 }}
                  className="custom-card"
                  cursor="pointer"
                  onClick={() => navigate(`/movies-details/${movie._id}`)}
                  background="#252526"
                  borderLeft="3px solid rgb(255, 0, 0)"
                  borderRight="3px solid rgb(0, 128, 0)"
                  borderTop="3px solid rgb(255, 255, 0)"
                  borderBottom="3px solid rgb(255, 255, 0)"
                >
                  <img
                    className="banner-image"
                    src={movie.bannerImg}
                    alt="movie banner"
                    borderRadius="lg"
                  />
                  <Heading padding={2} size="md" color="white">
                    {movie.movieName.slice(0, 40)}...
                  </Heading>
                </Card>
              ))}
            </Flex>
            <HStack spacing="24px" justify="center" margin="20px">
              <Button
                onClick={() => handleClick("prev")}
                isDisabled={currentPage === 1}
                colorScheme="teal"
              >
                Previous
              </Button>
              <Text color="white">
                Page {currentPage} of{" "}
                {Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)}
              </Text>
              <Button
                onClick={() => handleClick("next")}
                isDisabled={
                  currentPage ===
                  Math.ceil(filteredMovies.length / ITEMS_PER_PAGE)
                }
                colorScheme="teal"
              >
                Next
              </Button>
            </HStack>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CategoryMovie;
