import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import MovieSliders from "./../Screens/MovieSliders";
import { FaFireAlt, FaTelegram } from "react-icons/fa";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Image,
  Text,
  Textarea,
  position,
} from "@chakra-ui/react";
import { BiSolidCameraMovie } from "react-icons/bi";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { Hearts } from "react-loader-spinner";
import Category from "../Screens/Category";
import { TiThMenu } from "react-icons/ti";
import axios from "axios";
import * as mod from "./../../url";

const colors = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "teal",
  "cyan",
  "yellow",
];

const DownloadPage = () => {
  const params = useParams();
  const toast = useToast();
  const [movies, setMovies] = useState(null);
  const [buttonFunctions, setButtonFunctions] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(`${mod.api_url}/categry/get_categry`);
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getMovies = async () => {
    try {
      console.log("Fetching movies details for ID:", params.id);
      const response = await axios.get(
        `${mod.api_url}/movies/findBy/${params.id}`
      );
      if (response.status === 200) {
        console.log("Movie details fetched successfully:", response.data);
        setMovies(response.data);
      } else {
        console.error("Error fetching Movies:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Movies:", error);
    }
  };

  useEffect(() => {
    if (params.id) {
      getMovies();
    }
  }, [params.id]);

  useEffect(() => {
    const functions = [
      downloadMovie,
      downloadMovie,
      () =>
        window.open(
          "https://bc.co/?i=24mwusws5&utm_source=24mwusws5",
          "_blank"
        ),
      () => window.open("https://loanwaystore.com", "_blank"),
    ];
    const shuffledFunctions = functions.sort(() => Math.random() - 0.5);
    setButtonFunctions(shuffledFunctions);
  }, []);

  const movieName = localStorage.getItem("movieName");
  const bannerImg = localStorage.getItem("bannerImg");

  const downloadMovie = () => {
    const videoLink = localStorage.getItem("movieVideoLink");
    const movieName = localStorage.getItem("movieName");
    const bannerImg = localStorage.getItem("bannerImg");

    if (videoLink) {
      const link = document.createElement("a");
      link.href = videoLink;
      link.download = "movie.mp4";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Remove the video link from local storage
      // localStorage.removeItem("movieVideoLink");
      // localStorage.removeItem("movieName");
      // localStorage.removeItem("bannerImg");

      // Show a toast notification
      toast({
        title: "Download started.",
        description: "Your movie download has started.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Show a toast notification if no video link is found
      toast({
        title: "No video found Please Restart the Process of Downloading.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCardClick = () => {
    window.open("https://loanwaystore.com/eduvanz-quick-education-app");
    window.open("https://stake.com/?c=VvyhynH8", "_blank");
    // setTimeout(() => {
    //   window.open("https://bc.co/?i=24mwusws5&utm_source=24mwusws5", "_blank");
    // }, 0);
  };

  const handleWatchNowClick = () => {
    const videoLink = localStorage.getItem("movieVideoLink");

    if (videoLink) {
      const newWindow = window.open("", "_blank");
      newWindow.document.write(`
        <html>
          <head>
            <title>Watch Video</title>
          </head>
          <body style="margin:0; display:flex; align-items:center; justify-content:center; height:100vh; background-color:black;">
            <video controls autoplay style="width:100%; height:100%;">
              <source src="${videoLink}" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </body>
        </html>
      `);
    } else {
      // Show a toast or alert if no video link is found in localStorage
      toast({
        title: "No video link found.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box style={{ background: "whiteSmoke", height: "100%" }}>
        <Box>
          <>
            <nav
              className="navbar navbar-expand-lg bg-body-black"
              style={{ background: "whiteSmoke" }}
            >
              <div className="container-fluid">
                <span className="navbar-brand">
                  <Link to="https://loanwaystore.com/">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuHBOvXVQSQbb0Yadd7iA4jrXDbQqmIHFTyA&s"
                      alt="Logo"
                      style={{ width: "50px" }}
                    />
                  </Link>
                </span>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{ color: "white" }}
                  onClick={handleCardClick}
                >
                  <TiThMenu />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <span className="navbar-nav me-auto mb-1 mb-lg-0">
                    <span className="nav-item" onClick={handleCardClick}>
                      <Link to="https://loanwaystore.com/">
                        <Button
                          color="black"
                          marginRight={2}
                          colorScheme=""
                          to="/"
                        >
                          Home
                        </Button>
                      </Link>
                      <Link to="https://loanwaystore.com/">
                        <Button
                          color="black"
                          marginRight={2}
                          colorScheme=""
                          to="/"
                        >
                          About
                        </Button>
                      </Link>
                      <Link to="https://loanwaystore.com/">
                        <Button
                          color="black"
                          marginRight={2}
                          colorScheme=""
                          to="/"
                        >
                          Conact us
                        </Button>
                      </Link>
                      <Link to="https://loanwaystore.com/">
                        <Button
                          color="black"
                          marginRight={2}
                          colorScheme=""
                          to="/"
                        >
                          Privacy Policy
                        </Button>
                      </Link>
                    </span>
                  </span>
                  <form className="d-flex" role="search">
                    <span className="nav-item">
                      <span className="nav-link">
                        <Link to="https://loanwaystore.com/">
                          <Button
                            color="white"
                            marginRight={2}
                            colorScheme="blue"
                          >
                            Login
                          </Button>
                        </Link>
                      </span>
                    </span>
                  </form>
                </div>
              </div>
            </nav>
          </>
          <hr />
        </Box>
        <Box>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-3">
              <Box>
                <Button
                  colorScheme="white"
                  width="100%"
                  marginLeft={2}
                  height="80vh"
                >
                  <Image
                    src={bannerImg}
                    alt="Movie Banner Not Found Please 
                    Complete your All Step for
                    Downloading your Movie"
                    width="100%"
                    height="100%"
                    padding={3}
                    borderRadius="40px"
                  />
                </Button>
              </Box>
            </div>
            <div className="col-md-9">
              <Box width="100%" display="flex" flexWrap="wrap" overflowX="auto">
                <div style={{ textAlign: "center" }}>
                  <Link to="https://telegram.me/moviehub4u143">
                    <Button colorScheme="green">
                      <Image
                        src="https://moviehub4u.org/TeleGram-Download.png"
                        width="100px"
                        height="30px"
                      />
                    </Button>
                  </Link>

                  {categories?.map((cat, index) => (
                    <Button
                      key={cat.id}
                      as={Link}
                      to={`/category-filter-movies/${cat.catName}`}
                      marginRight={2}
                      colorScheme={colors[index % colors.length]}
                      margin={1}
                    >
                      {cat.catName}
                    </Button>
                  ))}
                </div>
              </Box>
              <Center>
                <hr style={{ color: "black", width: "80%" }} />
              </Center>
              <Link to="https://refpa7921972.top/L?tag=d_3574986m_1236c_&site=3574986&ad=1236">
                <Center>
                  <img
                    src="https://refpa7921972.top/img/AdAgent_1/d0daa1d5-d517-455b-986b-3b49e7ab67a3.gif"
                    alt=""
                    style={{ marginBottom: "20px", width: "50%" }}
                  />
                </Center>
              </Link>
              <Center>
                <hr style={{ color: "black", width: "80%" }} />
              </Center>
              <div
                className="row"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <Text textAlign="center" fontSize="x-large" fontWeight="800">
                  DOWNLOAD YOUR MOVIE
                  <Text textAlign="center" fontSize="small" fontWeight="300">
                    WITH DIFFERENT SERVERS
                  </Text>
                  <Text textAlign="center" fontSize="x-large" fontWeight="300">
                    {movieName}
                  </Text>
                </Text>

                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button colorScheme="orange" onClick={buttonFunctions[0]}>
                    Download Your Movie / Server 1
                  </Button>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button colorScheme="orange" onClick={buttonFunctions[1]}>
                    Download Your Movie / Server 2
                  </Button>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button colorScheme="blue" onClick={buttonFunctions[2]}>
                    Download Your Movie / Fast Server 3
                  </Button>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button colorScheme="blue" onClick={buttonFunctions[3]}>
                    Download Your Movie / Fast Server 4
                  </Button>
                </div>
              </div>
              <Center>
                <hr style={{ color: "black", width: "80%" }} />
              </Center>
              <Center>
                <Button colorScheme="green" onClick={handleWatchNowClick}>
                  Watch Your Movie Online
                </Button>
              </Center>
              <Center>
                <hr style={{ color: "black", width: "80%" }} />
              </Center>
              <Link to="https://loanwaystore.com/eduvanz-quick-education-app/">
                <Center>
                  <img
                    src="/banner_ads.jpg"
                    alt=""
                    style={{ marginBottom: "20px" }}
                  />
                </Center>
              </Link>
              <Center>
                <hr style={{ color: "black", width: "80%" }} />
              </Center>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default DownloadPage;
